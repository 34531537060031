import * as React from "react"
import { SEO } from "../../components/seo"
import Layout from '../../components/layout';
import { Link } from "gatsby";

const Press = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="h-96 bg-gradient-to-tl from-primary-dark to-primary-light relative">
        <div className="absolute w-full text-center mx-auto bottom-8 z-10 px-5">
          <h1 className="animate__animated animate__fadeInDown animate__slower font-heading text-center text-3xl lg:text-4xl text-white font-bold mb-4 leading-normal mx-auto max-w-[1000px]">Kim Nguyen-Penaloza Dominates Democratic Party Endorsement for CA-45</h1>
        </div>
      </section>
      <section className="px-10 lg:px-36 py-10 mt-10">
        <div className="w-[800px] mx-auto max-w-full text-base leading-loose space-y-6">
          
          <h2 className="font-bold text-2xl mb-8 text-center">Receiving Near-Unanimous Support From Delegates, Councilwoman Kim Nguyen-Penaloza is now The Presumptive Democratic Party Endorsed Candidate for CA-45</h2>

          <div className="text-justify space-y-6">
            <p>GARDEN GROVE, CA In a stunning result for a crowded primary, Councilwoman Kim Nguyen-Penaloza’s congressional campaign today announced that she won the Pre-Endorsement Conference for the California Democratic Party held on October 22nd. With a near-unanimous result of 93% from party delegates, Kim Nguyen-Penaloza is now the presumptive Democratic Party endorsed candidate in CA-45.</p>

            <p>Upon receiving the recommendation for endorsement following the meeting, Councilwoman Nguyen-Penaloza stated:</p>

            <p>“I’m honored by the overwhelming trust California Democratic Party delegates have placed in me for this seat and I’m proud to be the presumptive Democratic Party endorsed candidate for CA-45. With community, labor, and Democratic Party support, ours is a coalition that’s built to win. I look forward to working with our allies and national Democrats to flip this seat and defeat MAGA Michelle Steel in 2024!”</p>

            <p>At their pre-endorsement conferences held this weekend, the California Democratic Party placed a 70% threshold for candidates to be moved to consent at their endorsing convention in November. Despite the high bar, Councilwoman Nguyen-Penaloza's commanding victory is nearly unprecedented and it is rare for a candidate to receive a near-unanimous vote in a crowded primary. No other CA-45 candidate received more than 1 vote.</p>

            <p>The daughter of a Vietnamese refugee and a Mexican immigrant, Kim Nguyen-Penaloza was the first in her family to graduate high school and college. At just 25, she won a historic election as the youngest person and first Latina ever elected to the Garden Grove City Council. A leader on women’s issues, Councilwoman Nguyen-Penaloza has stood up to harassment and sought equality and justice throughout Southern California and has fought to ensure that immigrants, like her parents, have the dignity and opportunity to succeed in our communities. A homegrown leader, Councilwoman Nguyen-Penaloza attended Clinton-Mendenhall Elementary, Doig, Intermediate, and Santiago High School. As a healthcare expert, she will bring a much-needed public health background to Congress, having formerly worked for CalOptima and now as a Program Manager for a large health plan.</p>

            <p>CA-45 is one of the top pickup opportunities for Democrats in 2024. It is a Biden +6 district with a Democratic registration advantage of 5.6%. Asian-American Pacific Islander (AAPI) and Latino voters are a majority of the district at 51.6% -- with Vietnamese voters making up the largest block of AAPI voters at 58.4%. If elected, Kim Nguyen-Penaloza would be the first person of Vietnamese descent ever elected to Congress in California and only the third in US history. She would also be the first Asian-Latina in Congress and one of a growing group of millennials seeking to make change on our most crucial issues. Councilwoman Nguyen-Penaloza is the only candidate for Congress in the nation endorsed by both BOLD PAC and ASPIRE PAC, the political arms of both the Congressional Hispanic Caucus and the Asian-American, Native Hawaiian Pacific Islander Members of Congress.</p>
          </div>

          <div className="text-center mt-10 underline"><Link to="/press">Back to Press Page</Link></div>

        </div>
      </section>
    </Layout>
  )
}

export default Press

export const Head = ({location}) => <SEO title="Press" pathname={location.pathname} />
